export default defineNuxtRouteMiddleware(async (to, _from) => {
  const user = useSupabaseUser();
  const { user: decoratedUser, init } = useUser();
  console.time("auth middleware");

  await init();

  console.timeEnd("auth middleware");

  if (!user.value) {
    return navigateTo(`/auth/login?redirect=${to.fullPath}`);
  } else {
    await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (decoratedUser.value) {
          clearInterval(interval);
          resolve(decoratedUser.value);
        }
      }, 10);
    });
  }
});
